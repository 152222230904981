import { render, staticRenderFns } from "./MatchesTable.vue?vue&type=template&id=37ba7670&scoped=true"
import script from "./MatchesTable.vue?vue&type=script&lang=js"
export * from "./MatchesTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ba7670",
  null
  
)

export default component.exports