<template>
  <div class="profile-matches">
    <h3 class="title">
      {{ $t(`matches.${isCurrentUserProfile ? 'my' : 'player'}`) }}
    </h3>
    <loader v-if="loadingState.match" />
    <template v-else-if="matches.length">
      <matches-table :matches="matches" />
      <Pagination
        v-if="showPagination"
        :disabled="loadingState.match"
        :params="paginationParams"
        @onchange="setPage"
      />
    </template>
    <div v-else class="matches-placeholder">
      <template v-if="isCurrentUserProfile">
        {{ $t('matches.not-pariticipate_current') }}
        <Button
          tag="button"
          size="regular"
          type="secondary"
          :loading="false"
          :text="$t('ratings.toTournaments')"
          @click.native="goToTournaments"
        >
        </Button>
      </template>
      <template v-else>
        {{ $t('matches.not-pariticipate_other') }}
      </template>
    </div>
  </div>
</template>

<script>
import MatchesTable from '@components/Match/MatchesTable.vue';
import Pagination from '@src/components/v2/Pagination.vue';
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'Matches',
  components: {
    MatchesTable,
    Pagination,
    Button,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      match: true,
    },
    matches: [],
    // Pagination
    currentPage: 1,
    perPage: 12,
    total: 0,
    perPageSizes: [12, 36, 72],
  }),
  computed: {
    ...mapGetters('profile', ['getCurrentProfileHash']),

    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
        showBy: 'Показывать по',
      };
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },

    isCurrentUserProfile() {
      return this.getCurrentProfileHash === this.hash;
    },
  },
  watch: {
    perPage: {
      handler: 'reloadRating',
    },
    currentPage: {
      handler: 'reloadRating',
      immediate: true,
    },
  },
  methods: {
    ...mapActions('users', ['fetchUserMatches']),

    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.currentPage = page;
    },

    reloadRating() {
      this.loadingState.match = true;
      return this.loadTeamRating();
    },

    loadTeamRating: function () {
      this.fetchUserMatches({
        page: this.currentPage,
        limit: this.perPage,
        hash: this.hash,
      }).then((data) => {
        this.matches = data.matches;
        this.total = data.pagination.total;
        this.loadingState.match = false;
      });
    },

    goToTournaments() {
      this.$router.push({ name: 'tournaments' });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 30px 0 18px;
  @include max-tablet() {
    margin-top: 6px;
  }
}

.matches-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $dark-background;
  border-radius: 3px;
  box-shadow: $default-box-shadow;
  padding-top: 30px;
  padding-bottom: 30px;
  @include max-tablet() {
    padding: 30px 12px;
  }

  .button {
    margin-top: 12px;
  }
}

.pagination {
  margin-top: 20px;
}
</style>
