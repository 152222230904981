<template>
  <div>
    <loader v-if="loadingState.ratingsPage" />
    <template v-else>
      <div class="rating-header__row">
        <h3 slot="title" class="rating-header__title">
          {{ userRatingTypes.title }}
        </h3>
        <Button
          tag="button"
          size="regular"
          type="secondary"
          :loading="false"
          :text="userRatingTypes.buttonTitle"
          @click.native="switchRatingType"
        >
        </Button>
      </div>
      <RatingsListTable
        v-if="ratings.length"
        :list="ratings"
        :need-team-info="isTeamRating"
      />
      <div v-else class="rating-placeholder">
        <div class="placeholder-title">{{ placeholderText }}</div>
        <div v-if="isCurrentUserProfile" class="placeholder-action">
          <Button
            tag="button"
            size="regular"
            type="secondary"
            :loading="false"
            :text="$t('ratings.toTournaments')"
            @click.native="goToTournaments"
          >
          </Button>
        </div>
      </div>
    </template>
    <pvp-pagination
      v-if="showPagination"
      :params="paginationParams"
      :disabled="loadingState.ratingsPage"
      @onchange="setPage"
    />
  </div>
</template>

<script>
import RatingsListTable from '@components/RatingComponents/RatingsListTable.vue';
import Button from '@components/v2/ui/Button.vue';
import {
  fetchUserTeamsRatings,
  fetchUserPlayerRatings,
} from '@src/shared/api/rating';

export default {
  name: 'UserRatings',
  components: {
    Button,
    RatingsListTable,
  },
  props: {
    gamesIds: {
      type: Array,
      default: () => [],
    },
    profileHash: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      ratingsPage: true,
    },
    ratings: [],
    isTeamRating: true,

    // Pagination
    currentPage: 1,
    perPage: 8,
    total: 0,
    perPageSizes: [8, 16, 64],
  }),
  computed: {
    ...mapGetters('profile', ['getCurrentProfileHash']),

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },

    isCurrentUserProfile() {
      return this.getCurrentProfileHash === this.profileHash;
    },

    placeholderText() {
      if (this.isTeamRating) {
        return this.isCurrentUserProfile
          ? this.$t('ratings.user_current_teamPlaceholder')
          : this.$t('ratings.user_other_teamPlaceholder');
      }
      return this.isCurrentUserProfile
        ? this.$t('ratings.user_current_userPlaceholder')
        : this.$t('ratings.user_other_userPlaceholder');
    },

    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    userRatingTypes() {
      return this.isTeamRating
        ? {
            title: this.$t('ratings.teamRating'),
            buttonTitle: this.$t('ratings.showUserRating'),
          }
        : {
            title: this.$t('ratings.userRating'),
            buttonTitle: this.$t('ratings.showTeamRating'),
          };
    },
  },
  created() {
    this.isTeamRating = this.$route.query.rating === 'team';
    this.loadUserRating();
  },
  methods: {
    setPage({ page, perPage }) {
      this.currentPage = page;
      this.perPage = perPage;
      this.reloadRatings();
    },

    reloadRatings() {
      this.loadingState.ratingsPage = true;
      return this.loadUserRating();
    },

    switchRatingType() {
      this.isTeamRating = !this.isTeamRating;

      this.$router.push({
        query: {
          ...this.$route.query,
          rating: this.isTeamRating ? 'team' : 'player',
        },
      });

      this.loadUserRating();
    },

    loadUserRating() {
      const request = this.isTeamRating
        ? fetchUserTeamsRatings
        : fetchUserPlayerRatings;

      request({
        page: this.currentPage,
        limit: this.perPage,
        gameId: this.gamesIds.length ? this.gamesIds : null,
        userHash: this.profileHash,
      })
        .then((data) => {
          this.ratings = data.items;
          this.total = data.pagen.totalCount;
        })
        .finally(() => {
          this.loadingState.page = false;
          this.loadingState.ratingsPage = false;
        });
    },

    goToTournaments() {
      this.$router.push({ name: 'tournaments' });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 30px;
}

.rating-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $dark-background;
  border-radius: 3px;
  box-shadow: $default-box-shadow;
  padding-top: 30px;
  padding-bottom: 30px;
  @include max-tablet() {
    padding: 30px 12px;
  }

  .placeholder-title {
    font-size: 16px;
  }

  .placeholder-action {
    margin-top: 12px;
  }
}

.rating-header__row {
  margin: 20px 0;
  display: flex;
  align-items: center;
  @include max-tablet() {
    flex-wrap: wrap;
  }
}

.rating-header__title {
  margin-right: 10px;
  line-height: normal;
  @include max-tablet() {
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
    order: 2;
  }
}

.loader {
  margin-top: 20px;
}
</style>
