<template>
  <pvp-modal v-model="newTeamModalOpened" width="500">
    <div slot="modal-title">{{ $t('teams.create_title') }}</div>

    <new-team-form @success="onTeamCreated" />

    <div v-if="team" slot="modal-footer" class="controls">
      <pvp-btn
        v-if="!appIsMobile"
        variant="secondary"
        @click="newTeamModalOpened = false"
        >{{ $t('global.close') }}
      </pvp-btn>
      <pvp-btn :to="{ name: 'team', params: { hash: team.hash } }">
        {{ $t('teams.teamLink') }}
      </pvp-btn>
    </div>
  </pvp-modal>
</template>

<script>
import NewTeamForm from './NewTeamForm.vue';

export default {
  name: 'NewTeamModal',
  components: {
    NewTeamForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    newTeamModalOpened: false,

    newTeamHash: null,
  }),
  computed: {
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('application', ['appIsMobile']),

    team() {
      return this.getTeam(this.newTeamHash);
    },
  },
  watch: {
    newTeamModalOpened(value) {
      this.$emit('input', value);
    },
    value: {
      handler(value) {
        this.newTeamModalOpened = value;
        this.newTeamHash = null;
      },
      immediate: true,
    },
  },
  methods: {
    onTeamCreated({ team }) {
      this.newTeamHash = team.hash;
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  .button {
    @include max-tablet() {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
      @include min-tablet() {
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }
}
</style>
